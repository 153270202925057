import React from 'react';
import styled from 'styled-components';

export const InputFormCVV = styled.input`
  padding: 15px 15px 10px;
  font-family: Utopia Std;
  font-size: 16px;
  color: #001c72;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccd2e3;
  padding-left: 50px;
  background-image: url(/images/card-vector.svg);
  background-repeat: no-repeat;
  background-position: 15px 15px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  &.error {
    border-color: #bc2e3e;
    outline-color: #bc2e3e;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ValidationError = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

export default class InputCVV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: this.props.showError,
      isEditing: false,
      value: '',
      validated: false,
      error_message: 'This value is required.'
    };

    this.handleChange = this.handleChange.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
  }

  validateInput(value) {
    if (value.length === 0) {
      this.setState({
        showError: true,
        validated: false,
        error_message: 'This value is required.'
      });
    } else if (value.length === 3) {
      this.setState({
        showError: false
      });
    } else {
      this.setState({
        showError: true,
        validated: false,
        error_message: 'Please enter a valid CVV code.'
      });
    }
  }

  handleChange(event) {
    let targetValue = event.target.value;
    this.setState({ value: targetValue });
    this.props.onChange(targetValue);
    this.validateInput(targetValue);
  }

  toggleEditing(evt) {
    this.handleChange(evt);
    this.setState({ isEditing: !this.state.isEditing });
  }

  render() {
    return (
      <>
        <InputFormCVV
          type="number"
          maxLength="5"
          name={this.props.name}
          value={this.state.value}
          onChange={this.handleChange}
          onBlur={this.toggleEditing}
          placeholder="CVV"
          className={this.state.showError ? `error` : ``}
          pattern="[0-9]*"
        />

        {this.state.showError && (
          <ValidationError>{this.state.error_message}</ValidationError>
        )}
      </>
    );
  }
}
