import React from 'react';
import UserPaymentMethodV1 from './UserPaymentMethodV1';
import UserPaymentMethodV2 from './UserPaymentMethodV2';

const UserPaymentMethod = props => {
  const { designVersion } = props;
  switch (designVersion) {
    case 'version2':
      return <UserPaymentMethodV2 {...props} />;
    default:
    case 'version1':
      return <UserPaymentMethodV1 {...props} />;
  }
};

export default UserPaymentMethod;
