import React, { useContext, useEffect, useState } from 'react';
import * as S from './UserPaymentMethodV2.styles';
import LottieAnimation from '../LottieAnimation/LottieAnimation';
import useAuth from '../../hooks/useAuth';
import { CSSTransition } from 'react-transition-group';
import InputCCNumber from './InputCCNumber';
import InputCCExpiry from './InputCCExpiry';
import InputCVV from './InputCVV';
import { cleanCardNumber, validateCardNumber } from '../../utils/creditCard';
import { requestToken } from '../../utils/apiCheckoutCom';
import {
  getUserDetails,
  updatePaymentMethod2,
  updatePaypalPayment
} from '../../utils/user';
import { addDataLayer } from '../../utils/dataLayer';
import utmTracking from '../../utils/utmTracking';
import FunnelContext from '../../context/FunnelContext';
import { Builder } from '@builder.io/react';
import { getParamFromQuery } from '../../utils/getParamFromQuery';

const UserPaymentMethod = props => {
  const {
    isCheckoutComSandbox = false,
    expiredTokenRedirect = 'https://thepetlabco.com/pages/token-expired',
    viewEvent = 'magic_link_payment_method_update_page_view',
    updateEvent = 'magic_link_payment_method_update_selection',
    failEvent = 'magic_link_payment_method_update_failed',
    successEvent = 'magic_link_payment_method_update_successful'
  } = props;

  const [ccbuttonDisabled, setCcButtonDisabled] = useState(true);
  const [ccButtonClicked, setCcButtonClicked] = useState(false);
  const [paypalButtonClicked, setPaypalButtonClicked] = useState(false);
  const [showCardBox, setShowCardBox] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [userLoaded, setUserLoaded] = useState(false);

  const { setCurrentLayer, funnelUrl } = useContext(FunnelContext);

  const petlabCampaignTokenKey = 'petlab_campaign_token',
    klaviyoUserIdKey = 'utm_id',
    klaviyoCampaignIdKey = 'utm_campaign',
    redirectIfAuthFails = true,
    useStagingApiEndpoint = false;

  const { token: authToken, loading: loadingCover } = useAuth(
    petlabCampaignTokenKey,
    klaviyoUserIdKey,
    klaviyoCampaignIdKey,
    redirectIfAuthFails,
    expiredTokenRedirect,
    useStagingApiEndpoint
  );

  useEffect(() => {
    if (loadingCover && authToken) {
      getUserDetails({
        bearerToken: authToken
      })
        .then(results => {
          setUserInfo(results);
          setUserLoaded(true);
          addDataLayer(viewEvent, {
            sub_db_user_id: results.user.id
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [viewEvent, loadingCover, authToken, expiredTokenRedirect, setUserLoaded]);

  const handlePaypalButton = () => {
    if (Builder.isEditing) return;
    if (paypalButtonClicked) return;
    if (ccButtonClicked) return;
    setPaypalButtonClicked(true);
    setShowCardBox(false);

    addDataLayer(updateEvent, {
      payment_method: 'Paypal',
      sub_db_user_id: userInfo.user.id
    });

    updatePaypalPayment({
      bearerToken: authToken,
      success_return_url: `${funnelUrl}&layer=success`,
      failed_return_url: `${funnelUrl}&layer=failed`
    })
      .then(({ data }) => {
        setPaypalButtonClicked(false);
        window.location.href = data.return_url;
      })
      .catch(err => {
        console.log(err);
        setPaypalButtonClicked(false);
      });
  };

  const handleCardButton = () => {
    if (paypalButtonClicked) return;
    if (ccButtonClicked) return;

    if (!showCardBox) {
      addDataLayer(updateEvent, {
        payment_method: 'Credit Card',
        sub_db_user_id: userInfo.user.id
      });
    }

    setShowCardBox(!showCardBox);
  };

  const duration = 5000,
    onEnter = node => {
      node.style.marginTop = `-${node.offsetHeight}px`;
      node.style.marginBottom = `0px`;
    },
    onEntering = node => {
      node.style.marginTop = '';
      node.style.marginBottom = '';
    },
    onExit = node => {
      node.style.marginTop = '';
      node.style.marginBottom = '';
    },
    onExiting = node => {
      node.style.marginTop = `-${node.offsetHeight}px`;
      node.style.marginBottom = `0px`;
    };

  const [ccDetails, setCcDetails] = useState({
    ccn: '',
    exp: '',
    cvv: ''
  });
  const [validated, setValidated] = useState({
    ccn: false,
    exp: false,
    cvv: false
  });
  const [showError, setShowError] = useState({
    ccn: false,
    exp: false,
    cvv: false
  });

  const ExpiryNotExpired = () => {
    const expMonth = ccDetails.exp.split('/')[0];
    const expYear = ccDetails.exp.split('/')[1];

    const today = new Date();
    const expiryDate = new Date(`${expMonth}/1/${expYear}`);

    return expiryDate > today;
  };

  const validateCCDetail = props => {
    setValidated(
      Object.assign(validated, {
        ccn: validateCardNumber(props.ccn),
        exp:
          props.exp.length === 5 &&
          props.exp.indexOf('/') &&
          ExpiryNotExpired(),
        cvv: props.cvv.length === 3
      })
    );

    if (validated.ccn && validated.exp && validated.cvv) {
      setCcButtonDisabled(false);
    } else {
      setCcButtonDisabled(true);
    }
  };

  const triggerChangeNumber = value => {
    setCcDetails(
      Object.assign(ccDetails, {
        ccn: cleanCardNumber(value)
      })
    );
    validateCCDetail({
      ...ccDetails,
      ccn: cleanCardNumber(value)
    });
    setShowError({
      ...showError,
      ccn: true
    });
  };

  const triggerChangeExpiry = value => {
    setCcDetails(
      Object.assign(ccDetails, {
        exp: value
      })
    );
    validateCCDetail({
      ...ccDetails,
      exp: value
    });
    setShowError({
      ...showError,
      exp: true
    });
  };

  const triggerChangeCvv = value => {
    setCcDetails(
      Object.assign(ccDetails, {
        cvv: value
      })
    );
    validateCCDetail({
      ...ccDetails,
      cvv: value
    });
    setShowError({
      ...showError,
      cvv: true
    });
  };

  const triggerCCUpdate = () => {
    if (Builder.isEditing) return;
    if (ccbuttonDisabled) return;
    if (paypalButtonClicked) return;
    if (ccButtonClicked) return;
    setCcButtonClicked(true);

    const _isCheckoutComSandbox =
      getParamFromQuery('sandbox') === '1' || isCheckoutComSandbox;

    requestToken({
      sandbox: _isCheckoutComSandbox,
      cc_number: ccDetails.ccn,
      expiry_month: ccDetails.exp.split('/')[0],
      expiry_year: ccDetails.exp.split('/')[1],
      cvv: ccDetails.cvv,
      type: 'card',
      storeGeo: userInfo.user.storeGeo
    })
      .then(({ data }) => {
        updatePaymentMethod2({
          bearerToken: authToken,
          checkoutToken: data,
          utmTracking: utmTracking(window.location.pathname)
        })
          .then(() => {
            setCcButtonClicked(false);
            addDataLayer(successEvent, {
              payment_method: 'Credit Card',
              sub_db_user_id: userInfo.user.id
            });
            setCurrentLayer('success');
          })
          .catch(() => {
            setCcButtonClicked(false);
            addDataLayer(failEvent, {
              payment_method: 'Credit Card',
              sub_db_user_id: userInfo.user.id
            });
            setCurrentLayer('failed');
          });
      })
      .catch(() => {
        setCcButtonClicked(false);
      });
  };

  return (
    <>
      <S.Container>
        <S.PaypalButton
          onClick={handlePaypalButton}
          className={paypalButtonClicked || ccButtonClicked ? `disabled` : ``}
        >
          {paypalButtonClicked ? (
            <img src="/images/three-dots.svg" alt="loading" />
          ) : (
            <img src="/images/paypal-logo.png" alt="paypal logo" />
          )}
        </S.PaypalButton>
        <S.OrText>
          <span>OR</span>
        </S.OrText>
        <S.CardButton
          onClick={handleCardButton}
          className={paypalButtonClicked || ccButtonClicked ? `disabled` : ``}
        >
          Credit / Debit Card
        </S.CardButton>
        <CSSTransition
          in={showCardBox}
          timeout={duration}
          classNames="slide"
          unmountOnExit
          onEnter={onEnter}
          onEntering={onEntering}
          onExit={onExit}
          onExiting={onExiting}
        >
          <S.CardBoxContainer>
            <S.Body>
              <S.InputContainer className="mt-0">
                <S.InputLabel>Card Number</S.InputLabel>

                <InputCCNumber
                  value={ccDetails.ccn}
                  showError={showError.ccn && !validated.ccn}
                  onChange={triggerChangeNumber}
                />
              </S.InputContainer>

              <S.Row>
                <S.ColOne>
                  <S.InputContainer>
                    <S.InputLabel>Expiration Date</S.InputLabel>
                    <InputCCExpiry
                      value={ccDetails.exp}
                      showError={showError.exp && !validated.exp}
                      onChange={triggerChangeExpiry}
                    />
                  </S.InputContainer>
                </S.ColOne>
                <S.ColTwo>
                  <S.InputContainer>
                    <S.InputLabel>CVV Number</S.InputLabel>

                    <InputCVV
                      value={ccDetails.cvv}
                      showError={showError.cvv && !validated.cvv}
                      onChange={triggerChangeCvv}
                    />
                  </S.InputContainer>
                </S.ColTwo>
              </S.Row>

              <S.UpdateButton
                buttonDisabled={ccbuttonDisabled}
                buttonClicked={ccButtonClicked}
                onClick={triggerCCUpdate}
              >
                <span>RETRY PAYMENT</span>
              </S.UpdateButton>
            </S.Body>
          </S.CardBoxContainer>
        </CSSTransition>
      </S.Container>
      {(loadingCover || !userLoaded) && !Builder.isEditing && (
        <S.LoadingCover>
          <S.LottieAnimation>
            <LottieAnimation animationFile="https://cdn.builder.io/o/assets%2Fe9ed5fb489ab4b769611bc610a472a56%2F302066eb88bc4ee5a0f6bd4d894e32aa?alt=media&token=9068a8aa-9cea-4a77-b69c-f623c17720c0" />
          </S.LottieAnimation>
        </S.LoadingCover>
      )}
    </>
  );
};

export default UserPaymentMethod;
