import styled from 'styled-components';

export const Container = styled.div``;

export const PaypalButton = styled.div`
  background: #ffcc00;
  border-radius: 4px;
  padding: 15px 10px 12px;
  align-items: center;
  display: flex;
  cursor: pointer;
  &:hover {
    background: #eabb01;
  }
  & img {
    display: flex;
    margin: auto;
  }
  &.disabled {
    background: #eabb01;
    cursor: initial;
  }
  z-index: 99;
  position: relative;
`;

export const OrText = styled.div`
  font-family: 'Utopia Std';
  text-align: center;
  position: relative;
  z-index: 99;
  position: relative;
  background: #fff;
  padding: 10px 0;
  & > span {
    width: 50px;
    background: #fff;
    display: block;
    margin: 0 auto;
    z-index: 2;
    position: relative;
  }
  &:before {
    content: ' ';
    border-bottom: 1px solid #001c72;
    display: block;
    margin-top: 8px;
    width: 100%;
    position: absolute;
    z-index: 1;
  }
`;

export const CardButton = styled.div`
  background: #001c72;
  border-radius: 4px;
  padding: 18px 10px 16px;
  cursor: pointer;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: 4px;
  &:hover {
    background: #01175a;
  }
  &.disabled {
    background: #01175a;
    cursor: initial;
  }
  z-index: 99;
  position: relative;
`;

export const CardBoxContainer = styled.div`
  margin-top: 15px;
  z-index: 90;
  position: relative;
`;

export const LoadingCover = styled.div`
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
`;

export const LottieAnimation = styled.div`
  width: 300px;
  display: flex;
  margin: 0 auto;
  height: 300px;
  margin-top: calc((100vh - 300px) / 2);
`;

export const Body = styled.div`
  min-height: 100px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ColOne = styled.div`
  width: 50%;
  padding-right: 8px;
`;

export const ColTwo = styled.div`
  width: 50%;
  padding-left: 8px;
`;

export const InputContainer = styled.div`
  margin-top: 20px;
  &.mt-0 {
    margin-top: 0;
  }
`;
export const InputLabel = styled.div`
  color: #001c72;
  font-family: Utopia Std;
  font-size: 16px;
  margin-bottom: 5px;
`;

export const UpdateButton = styled.button`
  border-radius: 4px;
  background-color: #bc2e3e;
  text-align: center;
  font-size: 16px;
  padding: 15px 14px 12px;
  font-family: Neuzeit Grotesk;
  border: none;
  outline: none;
  color: #fff;
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
  letter-spacing: 2px;
  &:hover {
    background-color: #a02734;
  }

  ${({ buttonClicked }) => {
    return buttonClicked
      ? `
    &:before {
      content: "";
      height: 22px;
      width: 100%;
      background-image: url("/images/three-dots.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
    }
    & > span {
      display: none;
    }
   
    background-color: #a02734;
    cursor: initial;
      
    `
      : ``;
  }}

  ${({ buttonDisabled }) => {
    return buttonDisabled
      ? `
    opacity: 0.5;
    cursor:initial;
    &:hover {
      background-color:#BC2E3E;
    }
    `
      : ``;
  }}
`;
